.ah-layout {
  max-width: 100%;
}

.ah-layout--center {
  margin: 0 auto;
}

@media only screen and (max-width: 960px) { /* stylelint-disable-line unit-blacklist */
  .ah-layout {
    margin: 0 auto;
  }
}

.framed-page .ah-layout {
  margin: 0 auto;
}
