.ah-background-page {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  padding: 0 1.5rem;
  padding: 0 var(--page-padding-horizontal);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: rgb(63, 63, 63);
  background-color: var(--color-ui-white-noise);
  color: var(--color-theme-text);
}

.ah-background-page__body {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: var(--layout-width-medium);
  margin: 0 auto;
  padding: var(--page-padding-vertical) 0;
  z-index: 1;
}

.ah-background-page__background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.master-layout {
  display: flex;
  flex: 1 0 auto;
  z-index: 1;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.framed-page .form-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  float: left;
}

/* stylelint-disable unit-blacklist, declaration-no-important */
/* Break points requested by marketing */
.iframe-container {
  width: 50%;
  max-width: 480px;
  float: right;
}
@media (min-width: 1200px) {
  .iframe-container {
    max-width: 520px !important;
  }
}
@media (min-width: 1600px) {
  .iframe-container {
    max-width: 640px !important;
  }
}
@media (min-width: 2000px) {
  .iframe-container {
    max-width: 720px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .iframe-container {
    display: none;
  }
}
/* stylelint-enable */

.iframe-container .spring-chain {
  height: 100%;
}

.marketing-iframe {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 90vh;
  margin-right: -10px; /* stylelint-disable-line unit-blacklist */
  transform: translateY(-50%);
  border: 0;
}
