html,
body {
  height: 100%;
}

html {
  background-color: var(--color-theme-background--shade-2);
}

body {
  display: flex;
  flex-direction: column;
}

.ah-app-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 0;
  min-height: 100%;
}

/**
 * TODO: Axiom overrides, fix in Axiom
 */

.ah-app-container .ax-progress-button__content {
  display: flex;
}
