/* stylelint-disable unit-blacklist */
.background_svg {
  position: absolute;
  right: 0;
  height: 100%;
}

@media (max-width: 1024px) {
  .background_svg {
    display: none;
  }
}
