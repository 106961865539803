.ah-logo-page__logo_container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-right: auto;
}

.ah-logo-page__logo {
  display: flex;
  align-items: center;
  width: calc(var(--spacing-grid-size) * 20);
  height: calc(var(--spacing-grid-size) * 20);
  border-radius: 50%;
  background-color: rgb(var(--rgb-ui-white));
  line-height: 0;
}
